<div [ngClass]='containerClass' class='layout-wrapper'>
    <app-background-video />
    <app-navbar />
    @if (!isProduction) {
        <div class='layout-sidebar'>
            <app-sidebar />
        </div>
    }
    <p-toast />
    <router-outlet />
    <div class='layout-mask'></div>
</div>
