import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MenuChangeEvent } from '@/layout/models/menu-change-event.model';

@Injectable({ providedIn: 'root' })
export class MenuService {
  private _menuSource = new Subject<MenuChangeEvent>();
  private _resetSource = new Subject();

  menuSource$ = this._menuSource.asObservable();
  resetSource$ = this._resetSource.asObservable();

  onMenuStateChange(event: MenuChangeEvent): void {
    this._menuSource.next(event);
  }

  reset(): void {
    this._resetSource.next(true);
  }
}
