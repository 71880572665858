<div class='layout-navbar'>
  <div class='flex flex-1 gap-2 align-items-center justify-content-start'>
    <p-button icon='pi pi-file' [rounded]='true' severity='secondary' (onClick)='onSidebarToggle()' />
    <span class='font-bold text-xl'>{{ title }}</span>
  </div>

  <!-- Desktop -->
  <div class='hidden md:flex flex-1 gap-5 justify-content-center'>
    @for (navbarItem of navbarItems; track $index) {
      <p-button
        [label]='navbarItem.label'
        [severity]='navbarItem.active ? "primary" : "secondary"'
        [rounded]='true'
        size='small'
        (click)='navigate(navbarItem.routerLink)' />
    }
  </div>
  <!-- Mobile -->
  <div class='flex flex-1 md:hidden gap-2 justify-content-center'>
    @for (navbarItem of navbarItems; track $index) {
      <p-button
        [icon]='navbarItem.icon'
        [severity]='navbarItem.active ? "primary" : "secondary"'
        [rounded]='true'
        size='small'
        (click)='navigate(navbarItem.routerLink)' />
    }
  </div>

  <div class='flex flex-1 gap-2 align-items-center justify-content-end'>
    <span class='font-semibold hidden lg:block'>{{ getUsername() }}</span>
    <p-button icon='pi pi-user' [rounded]='true' severity='secondary' (onClick)='onSidebarMenuToggle()' />
  </div>
</div>

<p-sidebar [(visible)]='sidebarMenuVisible' position='right'>
  <p-menu [model]='profileMenuItems' />
  <div class='p-5'>{{ version }}</div>
</p-sidebar>
