<ng-container>
  @if (root && item.visible !== false) {
    <div class='layout-menuitem-root-text'>{{ item.label ?? '' | translate }}</div>
  }

  @if ((!item.routerLink || item.items) && item.visible !== false) {
    <a (click)='itemClick($event)'
       [attr.href]='item.url'
       [attr.target]='item.target' [ngClass]='item["class"]' pRipple tabindex='0'>
      <i [ngClass]='item.icon' class='layout-menuitem-icon'></i>
      <span class='layout-menuitem-text'>{{ item.label ?? '' | translate }}</span>
      @if (item.items) {
        <i class='pi pi-fw pi-angle-down layout-submenu-toggler'></i>
      }
    </a>
  }

  @if ((item.routerLink && !item.items) && item.visible !== false) {
    <a (click)='itemClick($event)'
       [attr.target]='item.target'
       [fragment]='item.fragment' [ngClass]='item["class"]'
       [preserveFragment]='item.preserveFragment'
       [queryParamsHandling]='item.queryParamsHandling' [queryParams]='item.queryParams' [replaceUrl]='item.replaceUrl'
       [routerLinkActiveOptions]="item.routerLinkActiveOptions||{ paths: 'exact', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' }"
       [routerLink]='item.routerLink' [skipLocationChange]='item.skipLocationChange'
       [state]='item.state'
       pRipple routerLinkActive='active-route' tabindex='0'>
      <i [ngClass]='item.icon' class='layout-menuitem-icon'></i>
      <span class='layout-menuitem-text'>{{ item.label ?? '' | translate }}</span>
      @if (item.items) {
        <i class='pi pi-fw pi-angle-down layout-submenu-toggler'></i>
      }
    </a>
  }

  @if (item.items && item.visible !== false) {
    <ul [@children]='submenuAnimation'>
      @for (child of item.items; track i; let i = $index) {
        <li>
          <app-menu-item [class]='child["badgeClass"]' [index]='i' [item]='child' [parentKey]='key' />
        </li>
      }
    </ul>
  }
</ng-container>

