<ul class='layout-menu'>
  @for (item of model; track item.id; let i = $index) {
    @if (!item.separator) {
      <li>
        <app-menu-item [index]='i' [item]='item' [root]='true' />
      </li>
    } @else {
      <li class='menu-separator'></li>
    }
  }
</ul>
