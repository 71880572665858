import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TranslationService } from '@/modules/i18n';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  protected readonly environment = environment;

  constructor(
    // Enabled by injection
    private _translationService: TranslationService,
    private _primengConfig: PrimeNGConfig,
  ) {
  }

  ngOnInit(): void {
    this._primengConfig.ripple = false;
  }
}
