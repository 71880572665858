import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, throwError } from 'rxjs';
import { environment } from '@environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<string>> {
    const token = this._authService.getUser()?.token;
    const endpoint = environment.apiUrl;

    if (!!endpoint && !!token && request.url.startsWith(endpoint)) {
      const authReq = request.clone({
        headers: new HttpHeaders({
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          Authorization: `Bearer ${token}`,
        }),
      });

      return next.handle(authReq).pipe(obs => this.logoutIf401(obs));
    } else {
      return next.handle(request).pipe(obs => this.processResponse(obs));
    }
  }

  private processResponse(obs: Observable<HttpEvent<string>>): Observable<HttpEvent<string>> {
    return obs.pipe();
  }

  private logoutIf401(obs: Observable<HttpEvent<string>>): Observable<HttpEvent<string>> {
    return obs.pipe(catchError(err => {
      if (err.status !== 401 && err.status !== 403) {
        return throwError(() => err);
      }
      this._authService.logout();

      return of('Logout Forbidden API call' as unknown as HttpEvent<string>);
    }));
  }
}
